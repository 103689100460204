/* Footer styles */
.footer {
    background: linear-gradient(180deg, #1765f5, #243b55);
    color: white; /* Set the text color */
    padding: 10px; /* Add padding for space */
    position: relative; /* Positioning the footer relative */
    width: 100%; /* Full width */
    max-height: 100px; /* Set maximum height for scrolling */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    text-align: center; /* Center the text */
   
}

/* Additional styles for footer content, if any */
.footer p,
.footer a {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    color: white; /* Set the text color */
    text-decoration: none; /* Remove default underline from links */
}

/* Hover effect for links */
.footer a:hover {
    text-decoration: underline; /* Underline on hover */
}
