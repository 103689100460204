
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.navbar-logo .logo {
  height: 40px;
  width: auto;
}
.menu-button {
  background: none;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
  display: block; /* Visible on both mobile and desktop */
  z-index: 2000; /* Ensure the button stays on top */
}

/* Sidebar styles for both mobile and desktop */
.nav-list {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background: linear-gradient(180deg, #1765f5, #243b55);
  padding-top: 60px;
  transition: transform 0.3s ease;
  z-index: 1000;
  transform: translateX(-100%); /* Hidden by default */
  list-style: none; /* Remove dots */
}

.nav-list.open {
  transform: translateX(0); /* Show sidebar when open */
}

.nav-item {
  margin: 10px 0;
  text-align: center;
}

.nav-link img {
  height: 40px;
  width: 180px;
  border-radius: 10px;
}

/* Desktop view */
@media (min-width: 769px) {
  .menu-button {
    display: block; /* Show the toggle button in desktop */
    position: fixed; /* Fix the button to the top of the screen */
    top: 10px;
    left: 10px;
  }

  .nav-list {
    transform: translateX(-100%); /* Sidebar hidden by default */
    width: 250px;
  }

  .nav-list.open {
    transform: translateX(0); /* Sidebar slides in when open */
  }

  .navbar-logo {
    display: none;
  }

  .nav-item {
    margin: 20px 0;
  }
}

/* Mobile view */
@media (max-width: 768px) {
  .menu-button {
    display: block; /* Show the hamburger menu button on mobile */
  }

  .nav-list {
    transform: translateX(-100%); /* Sidebar hidden initially */
  }

  .nav-list.open {
    transform: translateX(0); /* Show sidebar when open */
  }
}
.head {
  margin-left: 15px;
 
}
.menu-button {
  margin-right: 15px;
}
