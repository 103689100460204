.container {
  width: 100vw; /* Full width of the viewport */
  
}


.filter-buttons {
    padding: 2px 0;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;  /* For IE and Edge */
    scrollbar-width: none;     /* For Firefox */
  }
  
  .filter-buttons::-webkit-scrollbar {
    display: none;  /* Hide scrollbar for WebKit browsers */
  }
  .filter-buttons {
    padding: 2px 0;
    display: flex;                /* Aligns the buttons in a row */
    overflow-x: auto;             /* Allows horizontal scrolling */
    white-space: nowrap;          /* Prevents buttons from wrapping to the next line */
    -ms-overflow-style: none;     /* For IE and Edge */
    scrollbar-width: none;        /* For Firefox */
  }
  
  .filter-buttons::-webkit-scrollbar {
    display: none;  /* Hide scrollbar for WebKit browsers */
  }
  /* Professional background with a gradient */
  header.container-fluid {
    background: linear-gradient(180deg, #1765f5, #243b55);
    color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 0.2rem 0.5rem; /* Reduced padding */
  }
  
  /* Spacing and typography for header text */
  header h1 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 0.25rem; /* Reduced spacing below the h1 */
  }
  
  header p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem; /* Slightly smaller font size for compact look */
    margin-bottom: 0; /* Remove extra margin */
  }
  
  /* Optional padding for larger screens */
  @media (min-width: 768px) {
    header.container-fluid {
      padding: 0.25rem 1rem; /* Slightly more padding on larger screens */
    }
  }
  @media (max-width: 768px) {
    .mobile-height {
        height: 120px !important;
    }
}
